import React from "react";
import "./popup.css";

export default function Popup(props) {
  return (
    <div className="popup">
      <div className="popup_inner">
        <p className="popup_text">
          The cookie settings on this website are set to "allow cookies" to give
          you the best browsing experience possible. If you continue to use this
          website without changing your cookie settings or you click "Accept"
          below then you are consenting to this.
        </p>
        <a className="popup_button" href="/" onClick={props.closePopup}>
          Close
        </a>
      </div>
    </div>
  );
}
